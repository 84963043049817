<template>
    <div class="children-page">
        <div>
            <header-top :backShow="true" />
            <router-view />
        </div>
    </div>
</template>

<script>
import HeaderTop from "../header";
export default {
    components: { 
        HeaderTop 
    },
};
</script>

<style lang="scss" scoped>

</style>
