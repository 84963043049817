const TokenKey = 'zlToken'
const Userinfo = 'userinfo'
const InvitCode = 'invitCode'
const idAuthZheng = 'idAuthZheng'
const idAuthFan = 'idAuthFan'
const authInfo = 'authInfo'
const Agreement = 'NimbleAgreement'
const TokenRes = 'KlTokenRes'
const local = window.localStorage;

//存取token到本地--->本地缓存
export function getToken() {
	return local.getItem(TokenKey);
}
export function setToken(token) {
	return local.setItem(TokenKey, token)
}
export function removeToken() {
	return local.removeItem(TokenKey)
}

//存取用户认证信息--->本地缓存
export function getAuthInfo() {
	return local.getItem(authInfo)
}
export function setAuthInfo(info) {
	return local.setItem(authInfo, info)
}
export function removeAuthInfo() {
	return local.removeItem(authInfo)
}

//存取用户信息--->本地缓存
export function getUserinfo() {
	return local.getItem(Userinfo)
}
export function setUserinfo(info) {
	return local.setItem(Userinfo, info)
}
export function removeUserinfo() {
	return local.removeItem(Userinfo)
}

// 获取邀请码
export function getInvitCode() {
	return local.getItem(InvitCode)
}
export function setInvitCode(data) {
	return local.setItem(InvitCode, data)
}
export function removeInvitCode() {
	return local.removeItem(InvitCode)
}

// 获取身份证认证图片---正面
export function getIdAuthZheng() {
	return local.getItem(idAuthZheng)
}
export function setIdAuthZheng(data) {
	return local.setItem(idAuthZheng, data)
}
export function removeIdAuthZheng() {
	return local.removeItem(idAuthZheng)
}
// 获取身份证认证图片----反面

export function getIdAuthFan() {
	return local.getItem(idAuthFan)
}
export function setIdAuthFan(data) {
	return local.setItem(idAuthFan, data)

}
export function removeIdAuthFan() {
	return local.removeItem(idAuthFan)
}

//储存灵活协议状态
export function getAgreementInfo() {
	return sessionStorage.getItem(Agreement)
}
export function setAgreementInfo(data) {
	return sessionStorage.setItem(Agreement, data)

}
export function removeAgreementInfo() {
	return sessionStorage.removeItem(Agreement)
}

//存储token相关信息
export function getTokenRes() {
	return local.getItem(TokenRes)
}
export function setTokenRes(data) {
	return local.setItem(TokenRes, data)

}
export function removeTokenRes() {
	return local.removeItem(TokenRes)
}

// 清除所有缓存
export function removeAllUserInfo() {
	local.removeItem(TokenKey)
	local.removeItem(Userinfo)
	local.removeItem(authInfo)
	return;
}