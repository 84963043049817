import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import './style/base.css'
import { Toast } from 'vant';
import Vconsole from 'vconsole'
import uploader from 'vue-simple-uploader'

// 将所有 Toast 设置为背景不可点击
Toast.setDefaultOptions({ forbidClick: true });

Vue.use(uploader)
//测试环境打开调试
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
	let vConsole = new Vconsole()
	Vue.use(vConsole)
}

Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$toast = Toast;


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')




//h5 页面不希望字体跟随系统字体大小时使用
if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
	handleFontSize();
} else {
	if (document.addEventListener) {
		document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
	} else if (document.attachEvent) {
		document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
	}
}
function handleFontSize() {
	// 设置网页字体为默认大小
	WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
	// 重写设置网页字体大小的事件
	WeixinJSBridge.on('menu:setfont', function () {
		WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
	});
}


