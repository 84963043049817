import { httpPost } from "@/utils/fetch";

// 获取当前用户所有协议
export const listAgreements = (params) => {
    return httpPost('/java-signplatform-cloud/agreement/listAgreements', params)
}

// 签署完毕回调
export const agreementCallback = (params) => {
    return httpPost('/java-signplatform-cloud/agreement/agreementCallback', params)
}