import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/'
import childrenRoute from './children';

Vue.use(VueRouter)

const routes = [
	{
		path: '/index',
		component: Layout,
        redirect: 'index',
		children:[
			{
				path: '/index',
				name: 'index',
				component: () => import( '@/views/home/index.vue' ),
				meta: {
					title: '首页',
					index: 1
				}
			},
			{
				path: '/message',
				name: 'message',
				component: () => import( '@/views/message/index.vue' ),
				meta: {
					title: '消息',
					index: 1
				}
			},
			{
				path: '/user',
				name: 'user',
				component: () => import( '@/views/user/user' ),
				meta: {
					title: '我的',
					index: 1
				}
			},
		]
	},
	{
		path: '/',
		name: 'transfer',
		component: () => import( '@/views/home/transfer.vue' ),
		meta: {
			title: '过渡',
			index: -1
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( '@/views/user/login.vue' ),
		meta: {
			title: '登录',
			index: 0
		}
	},
	{
		path: '/detail',
		name: 'detail',
		component: () => import( '@/views/home/detail.vue' ),
		meta: {
			title: '合同详情',
			index: 2
		}
	},
	{
		path: '/complaintDetail',
		name: 'complaintDetail',
		component: () => import( '@/views/user/complaintDetail.vue' ),
		meta: {
			title: '合同详情',
			index: 3
		}
	},
	childrenRoute
]

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	scrollBehavior: () => ({ y: 0 }),
	routes
})


export default router
