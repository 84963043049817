//封装请求
import axios from 'axios'
// import config from "../config/config";
import store from "@/store";
import router from '@/router/index'
import { Toast } from 'vant';
import { removeAllUserInfo, getTokenRes } from './auth'
import { getToken } from './cache'

// 将所有 loading Toast 设置为背景不可点击
Toast.setDefaultOptions('loading', { forbidClick: true });

// 表示跨域请求时是否需要使用凭证
axios.defaults.withCredentials = false
// 允许跨域
axios.defaults.headers.post['Access-Control-Allow-Origin-Type'] = '*'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	timeout: 30000 // request timeout
})


//记录请求数量
let requestCount = 0;
// 定义一个flag 判断是否刷新Token中
let isRefreshing = false;
// 失败接口
let requests = [];
let pending = {} // 网络请求记录
const TOKEN_REFRESH = '/java-signplatform-cloud/login/refreshToken';         // 刷新token返回401的话，直接跳转到登录页面

////// 请求 ///////
service.interceptors.request.use(
	config => {
		if (getTokenRes()) {
            let token_res = JSON.parse(getTokenRes())
            // config.headers.Authorization = token_res.accessToken;
            config.headers['accessToken'] = getToken();
            config.headers.refreshToken = token_res.refreshToken
		}
		if(store.state.app.isAllLoading){
			startLoading();
		}	
		return config;
	},
	error => {
		// do something with request error
		qendLoading();
		console.log('requestError-----------',error) // for debug
		return Promise.reject(error)
	}
)

///// 响应 ////////
service.interceptors.response.use(
	response => {
		if(store.state.app.isAllLoading){
			endLoading();
		}
		let { status, data } = response;

		return data	
	},
	error => {
		qendLoading();
		console.log('err.response-------------' , error.response) // for debug
		let {status, data, config} = error.response || {};
        let errMessage = "服务器异常";

        if (+status === 401 && error.config.url !== TOKEN_REFRESH) {
            if (!isRefreshing) {
                isRefreshing = true
                console.log('user/RefreshToken-------');
                //调用刷新token的接口
                return store.dispatch("user/RefreshToken").then(res => {
                   
                    let { accessToken, refreshToken } = res
                    console.log('RefreshToken----', accessToken, refreshToken);
                    config.headers.accessToken = accessToken
                    config.headers.refreshToken = refreshToken
                    // token 刷新后将数组的方法重新执行
                    requests.forEach((cb) => cb(accessToken, refreshToken))
                    requests = [] // 重新请求完清空
                    return service(config)
                }).catch(err => {}).finally(() => {
                    // debugger
                    isRefreshing = false
                })
            } else {
                // 返回未执行 resolve 的 Promise
                return new Promise(resolve => {
                    // 用函数形式将 resolve 存入，等待刷新后再执行
                    requests.push((accessToken, refreshToken) => {
                        console.log('requests.push',accessToken,refreshToken);
                        config.headers.accessToken = accessToken
                        config.headers.refreshToken = refreshToken
                        resolve(service(config))
                    })
                })
            }

        } else if (+status === 401 && error.config.url === TOKEN_REFRESH) {
            //清空所有存储信息
            Toast({
                message: '超时，请重新登录',
            })
            store.dispatch('user/loginOut');
            setTimeout(()=>{
                router.replace({
                    path:"/login"
                })
            }, 1000)
            return Promise.reject(error)
        }

		if( status === 400 ){
			Toast({
				message: errMessage,
			});
			return;
		}
		if( status >= 500 ){
			errMessage = status + " 服务器异常"
		}
		Toast({
			message: errMessage,
		})
        // setTimeout(() => {
        //     if( status === 401 ){
		// 		//清空所有存储信息
		// 		store.dispatch('user/loginOut');
		// 		router.replace({
		// 			path:"/login"
		// 		})
        //         return;
        //     }
        // }, 1500);
		return Promise.reject(error)
	}
)


function startLoading(){
	if(requestCount === 0){
		Toast.loading({
			message: '加载中...',
		});
	}
	requestCount++;
}
function endLoading(){
	requestCount--;
	if(requestCount === 0){
		Toast.clear();
	}
	
}
function qendLoading(){
	requestCount=0;
	Toast.clear();
	
}
function httpGet(url, params) {
	return service({
		method: 'get',
		url: url,
		params
	})
}
function httpPost(url, params) {
	return service({
		method: 'post',
		url: url,
		data: params
	})
}
export {
	httpGet,
	httpPost
}
export default service