import {
    getAuthInfo, getUserinfo, removeAllUserInfo, setAuthInfo,
    setAgreementInfo, setTokenRes, getTokenRes, removeTokenRes
} from "@/utils/auth"
import {
    getToken, setToken, removeToken, getCheckPlatform, setCheckPlatform,
    removeCheckPlatform, getWxAuthed, removeWxAuthed, setWxAuthed, setLoginPhone,
    getLoginPhone, removeLoginPhone
} from "@/utils/cache"
import { getCheckStatus, tokenRefresh } from "@/api/user";
import { listAgreements } from '@/api/agreement'
import { Toast } from 'vant'


const state = {
    loginPhone: getLoginPhone(),
    token: getToken(),
    userInfo: getUserinfo(),
    authInfo: getAuthInfo(),
    checkPlatform: getCheckPlatform(),
    wxAuthed: getWxAuthed()
}

const mutations = {
    set_token: (state, token) => {
        state.token = token
    },
    set_loginPhone: (state, phone) => {
        state.loginPhone = phone
    },
    set_info: (state, info) => {
        state.userInfo = info
    },
    set_authInfo: (state, obj) => {
        state.authInfo = obj
    },
    set_checkPlatform: (state, obj) => {
        state.checkPlatform = obj
    },
    set_wxAuthed: (state, obj) => {
        state.wxAuthed = obj
    },
}

const actions = {
    loginOut({ commit }) {
        commit('set_token', '');
        removeAllUserInfo();
        removeToken();
        removeCheckPlatform();
        removeWxAuthed();
        removeLoginPhone();
        removeTokenRes()
    },
    RefreshToken({ commit }) {
        return new Promise((resolve, reject) => {
            const refreshToken = getTokenRes() ? JSON.parse(getTokenRes()).refreshToken : {};
            tokenRefresh({ refreshToken }).then((response) => {
                const { data, message, code } = response;
                if (code === 0) {
                    console.log('setToken(data.accessToken)--', data.accessToken);
                    setToken(data.accessToken)
                    commit('set_token', data.accessToken);
                    setTokenRes(JSON.stringify(data))
                    resolve(data)
                } else {
                    reject({ message, code })
                }
            }).catch((error) => { reject(error) })
        })
    },
    setTokenAction({ commit }, token) {
        setToken(token);
        commit('set_token', token);
    },
    setTokenResAction({ commit }, res) {
        setTokenRes(res);
    },
    setLoginPhoneAction({ commit }, phone) {
        setLoginPhone(phone);
        commit('set_loginPhone', phone);
    },
    setWxAuthedAction({ commit }, wxAuth) {
        setWxAuthed(wxAuth);
        commit('set_wxAuthed', wxAuth);
    },
    setAuthInfo({ commit }) {
        return new Promise(resolve => {
            getCheckStatus().then((res) => {
                let { status, statusMessage, data } = res;
                if (status) {
                    setAuthInfo(JSON.stringify(data));
                    commit('set_authInfo', data);
                    resolve(data)
                } else {
                    Toast.clear();
                    Toast({
                        message: statusMessage
                    })
                }
            });
        })
    },
    checkNimbleAgreements({ commit }) {
        return new Promise((resolve, reject) => {
            listAgreements().then(res => {
                let { code, data, message } = res;
                if (code === 0) {
                    let agreements = data || {};
                    setAgreementInfo(JSON.stringify(agreements))
                    //灵活协议
                    if (agreements.laborId) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }

                } else {
                    reject(message)
                }
            })
        })
    },
    setCheckPlatform({ commit }, type) {
        setCheckPlatform(type);
        commit('set_checkPlatform', type);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
