<template>
    <div class="mobileWidth">
        <div>
            <router-view></router-view>
            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from "./footer";
export default {
    components: { Footer },
};
</script>

<style lang="scss" scoped>

</style>
