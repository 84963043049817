
const state = {
    isAllLoading: true,
}

const mutations = {
    set_loading: (state, bool) => {
        state.isAllLoading = bool
    },
}

const actions = {
    setLoading({ commit },bool) {
        commit('set_loading', bool);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
