<template>
	<div class="mobileFooter">
		<ul>
			<li v-for="(item, index) in list" :key="index" :class="{ active: checkUrl === item.url }" @click="open(item)">
				<img :src="checkUrl === item.url ? item.checkImg : item.img">
				<span>{{ item.name }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
    export default {
        name: "footerBottom",
		data() {
            return {
                list: [
					{ name: '首页', img: require('@/assets/yq/index-icon.png'), checkImg: require('@/assets/yq/index-icon-active.png'), url: '/index' },
					// { name: '消息', img: require('@/assets/yq/message-icon.png'), checkImg: require('@/assets/yq/message-icon-active.png'), url: '/message' },
					{ name: '我的', img: require('@/assets/yq/mine-icon.png'), checkImg: require('@/assets/yq/mine-icon-active.png'), url: '/user' },
				],
                checkUrl: '/index'
			}
		},
		created() {
			this.checkUrl = this.$route.path;
		},
		methods: {
            open(item) {
                this.checkUrl = item.url;
				this.$router.push(
					{
						path: item.url
					}
				)
			}
		}
    }
</script>

<style lang="scss" scoped>
	.mobileFooter {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 50px;
		background-color: #ffffff;
		width: 100%;
		box-shadow: 0px 0px 12px 0px rgba(141, 141, 186, 0.1);
		z-index: 3000;
		ul {
			height: 100%;
			display: flex;
			align-items: center;
			li {
				
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				color: #565A67;
				img {
					width: 26px;
					// margin-bottom: 3px;
				}
				&.active {
					color: #4B5EB4;
				}
			}
		}
	}
</style>
