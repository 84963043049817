<template>
    <div id="app">
        <transition :name="transitionName">
            <router-view :key="key" />
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            transitionName: ""
        }
    },
    computed: {
        key() {
            return this.$route.name !== undefined? this.$route.name +new Date(): this.$route +new Date()
        }
    },   
    watch: {
        $route(to,from){
            //实现路由跳转动画
            this.transitionName = "";
            if (to.meta.index > from.meta.index) {
                this.transitionName = "slide-left";
            } 
            else if (to.meta.index < from.meta.index){
                this.transitionName = "slide-right";
            }
        }
    }
};
</script>
<style lang="scss">
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    transition: all 300ms;
    position:fixed;
    width: 100%;
    height: 100vh;
}

.slide-right-enter {
    // opacity: 0;
    transform: translate(-100%);
}

.slide-right-leave-active {
    // opacity: 0;
    transform: translateX(100%);
}

.slide-left-enter {
    // opacity: 0;
    transform: translateX(100%);
}

.slide-left-leave-active {
    // opacity: 0;
    transform: translateX(-100%);
}
</style>
