import Cookies from 'js-cookie'
const TokenKey = 'zlToken'
const LoginPhone = 'LoginPhone'
const CheckPlatform = 'CheckPlatform'
const WxAuthed = 'WxAuthed'

//token相关
export const getLoginPhone = () => Cookies.get(LoginPhone)
export const setLoginPhone = (phone) => Cookies.set(LoginPhone, phone, { expires: 0.2 })
export const removeLoginPhone = () => Cookies.remove(LoginPhone)
//登陆手机号码
export const getToken = () => Cookies.get(TokenKey)
export const setToken = (token) => Cookies.set(TokenKey, token, { expires: 0.2 })
export const removeToken = () => Cookies.remove(TokenKey)
//认证类型
export const setCheckPlatform = (type) => Cookies.set(CheckPlatform, type, { expires: 0.2 })
export const getCheckPlatform = () => Cookies.get(CheckPlatform)
export const removeCheckPlatform = () => Cookies.remove(CheckPlatform)
//微信授权状态相关
export const getWxAuthed = () => Cookies.get(WxAuthed)
export const setWxAuthed = (auth) => Cookies.set(WxAuthed, auth, { expires: 0.2 })
export const removeWxAuthed = () => Cookies.remove(WxAuthed)


