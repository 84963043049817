<template>
    <div class="header-top">
        <div class="nav-titlebar">
            <div class="back" v-if="backShow" @click="back">
                <img src="@/assets/yq/back.png" />
            </div>
            <div class="bar-title">{{title}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            backShow: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                title: ""
            }
        },
        methods: {
            back() {
                let path = this.$route.path;
                switch (path) {
                    case "/threeAuth":
                        this.$router.replace("/authMain");
                        break;
                    case "/authMain":
                        this.$router.replace("/user");
                        break;
                    case "/ocrIdAuth":
                        this.$router.replace("/authMain");
                        break;
                    default:
                        this.$router.go(-1);
                        break;
                }
                
                
            }
        },
        created(){
            this.title = this.$route.meta.title;
        },
    }
</script>

<style lang="scss" scoped>
.header-top{
    position: fixed;
    width: 100vw;
    height: 48px;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(-90deg, #4657A3, #2C3C82);
    z-index: 100;
    .nav-titlebar{
        height: 100%;
        // width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .back{
            position: absolute;
            top: 50%;
            left: 20px;
            margin-top: -10px;
            img{
                width: 10px;
                height: 18px;
            }
        }
        .bar-title{
            font-size: 4.8vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            color: #fff;
        }
    }
}
</style>