import Children from '@/layout/children/children'

const routes = {
    path: '/auth',
    component: Children,
    redirect: 'authMain',
    children: [
        {
            path: '/authMain',
            name: 'authMain',
            component: () => import('@/views/user/authMain.vue'),
            meta: {
                title: '身份认证',
                index: 2
            }
        },
        {
            path: '/userInfo',
            name: 'userInfo',
            component: () => import('@/views/user/userInfo.vue'),
            meta: {
                title: '个人信息',
                index: 2
            }
        },
        {
            path: '/certificate',
            name: 'certificate',
            component: () => import('@/views/user/certificate.vue'),
            meta: {
                title: '我的证书',
                index: 2
            }
        },
        {
            path: '/complaint',
            name: 'complaint',
            component: () => import('@/views/user/complaint.vue'),
            meta: {
                title: '我的投诉',
                index: 2
            }
        },
        {
            path: '/opinion',
            name: 'opinion',
            component: () => import('@/views/user/opinion.vue'),
            meta: {
                title: '意见反馈',
                index: 2
            }
        },
        {
            path: '/indexComplain',
            name: 'indexComplain',
            component: () => import('@/views/home/indexComplain.vue'),
            meta: {
                title: '投诉',
                index: 3
            }
        },
        {
            path: '/threeAuth',
            name: 'threeAuth',
            component: () => import('@/views/user/threeAuth.vue'),
            meta: {
                title: '三要素认证',
                index: 3
            }
        },
        {
            path: '/authRes',
            name: 'authRes',
            component: () => import('@/views/user/authRes.vue'),
            meta: {
                title: '身份认证',
                index: 6
            }
        },
        {
            path: '/authError',
            name: 'authError',
            component: () => import('@/views/user/authError.vue'),
            meta: {
                title: '身份认证',
                index: 6
            }
        },
        {
            path: '/messages',
            name: 'messages',
            component: () => import('@/views/message/messages.vue'),
            meta: {
                title: '签约提醒',
                index: 2
            }
        },
        {
            path: '/contractMessages',
            name: 'contractMessages',
            component: () => import('@/views/message/contractMessages.vue'),
            meta: {
                title: '合同提醒',
                index: 2
            }
        },
        {
            path: '/systemMessages',
            name: 'systemMessages',
            component: () => import('@/views/message/systemMessages.vue'),
            meta: {
                title: '通知公告',
                index: 2
            },
        },
        {
            path: '/officialMessage',
            name: 'officialMessage',
            component: () => import('@/views/message/officialMessage.vue'),
            meta: {
                title: '公告详情',
                index: 3
            }
        },
        {
            path: '/agreement',
            name: 'agreement',
            component: () => import('@/views/user/agreement.vue'),
            meta: {
                title: '服务条款',
                index: 3
            }
        },
        {
            path: '/ocrIdAuth',
            name: 'ocrIdAuth',
            component: () => import('@/views/user/ocrIdAuth.vue'),
            meta: {
                title: '身份认证',
                index: 3
            }
        },
        {
            path: '/ocrFace',
            name: 'ocrFace',
            component: () => import('@/views/user/ocrFace.vue'),
            meta: {
                title: '身份认证',
                index: 4
            }
        },
        {
            path: '/modifyPhone',
            name: 'modifyPhone',
            component: () => import('@/views/user/modifyPhone.vue'),
            meta: {
                title: '修改手机',
                index: 3
            }
        },
        {
            path: '/agileAgreement',
            name: 'agileAgreement',
            component: () => import('@/views/home/agileAgreement.vue'),
            meta: {
                title: '卓猎云签',
                index: 3
            }
        },
        {
            path: '/faceTransfer',
            name: 'faceTransfer',
            component: () => import('@/views/user/faceTransfer.vue'),
            meta: {
                title: '跳转',
                index: 3
            }
        },
    ],
}

export default routes;