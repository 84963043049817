import {httpPost} from "@/utils/fetch";

// 登录接口
export const zlLogin = params => {
    return httpPost('/java-signplatform-cloud/login/loginByCheckCode',params)
}
//登录页面获取验证码
export const getVerifyCode = params => {
    return httpPost('/java-signplatform-cloud/login/pushCheckCode',params)
}
//三要素手机号验证发送验证码
export const threeVerifyCode = params => {
    return httpPost('/check/threeCheckSMSCode',params);
}
//三要素提交认证
export const threeSubmitAuth = params => {
    return httpPost('/check/threeCheck',params);
}
//新增意见反馈
export const addFeedback = params => {
    return httpPost('/feedback/save',params);
}
//获取当前登录客户个人信息
export const getUserInfo = () => {
    return httpPost('/java-signplatform-cloud/account/getCurAccountInfo');
}
//获取当前登录客户所有证书列表
export const getCertificateList = () => {
    return httpPost('/certificate/list');
}
//修改绑定手机号
export const updatePhone = (params) => {
    return httpPost('/customer/update/phone',params);
}
//获取用户认证状况
export const getCheckStatus = () => {
    return httpPost('/check/getCheckStatus');
}
//根据手机号获取客户邀请码
export const getInvitationCode = (params) => {
    return httpPost('/customer/get/code',params);
}
//发送语音短信
export const sendVoiceCode = (params) => {
    return httpPost('/java-signplatform-cloud/login/pushVoiceCheckCode',params);
}
//人脸识别拆分第一步身份证ocr上传
export const livePersonAuditFirstOcr = (params) => {
    return httpPost('/check/livePersonAuditFirstOcr',params);
}
//人脸识别拆分第二步活体视频上传
export const livePersonAuditTwo = (params) => {
    return httpPost('/check/livePersonAuditTwo',params);
}
//人脸识别(身份证信息+活体视频)
export const livePersonAuditByIdCard = (params) => {
    return httpPost('/check/livePersonAuditByIdCard',params);
}
// 微信OAuth2 Code解析
export const postWxCode = (params) => {
    return httpPost('/customer/oauth2/code',params)
}
// e签宝三要素认证、发送短信
export const eSignThreeKeySms = (params) => {
    return httpPost('/check/eSignThreeKey',params)
}
// e签宝三要素认证、短信校验
export const eSignThreeKeyCheck = (params) => {
    return httpPost('/check/eSignThreeKeyCheck',params)
}
// e签宝刷脸核身
export const eSignIndividualFace = (params) => {
    return httpPost('/check/eSignIndividualFace',params)
}
// 刷新Token
export const tokenRefresh = (params) => {
    return httpPost('/java-signplatform-cloud/login/refreshToken',params)
}
